import React, { Component } from 'react';

export class Endbar extends Component {
    render() {
        return (
            <div>
                <div id="endbar" className="text-center">
                    <div className="container" >
                        <div className="section-title" >
                            <h2>TKS MEDIA AGENCY</h2>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}